.navbar {
    width: 100%;
    height: 100px;
    background-color: #121619;
    display: flex;
    flex-direction: row;
}
  
.navbar .leftSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 150px;
}
  
.navbar .leftSide h1 {
    width: 60px;
    background: linear-gradient(90deg, rgba(254,240,4,1) 0%, rgba(247,5,244,1) 100%, rgba(252,176,69,1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
  
.navbar .rightSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
}
  
.navbar .leftSide .hiddenLinks a.active,
.navbar .rightSide a.active {
    background: linear-gradient(#3ff8fb, #aee8e6);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; /* Active link color */
}

.navbar .leftSide .hiddenLinks a:hover,
.navbar .rightSide a:hover {
  color: gray; /* Hover color */
}

.navbar .rightSide button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}
.navbar .rightSide button:hover {
    color: grey;
}
  
.navbar .rightSide svg {
    font-size: 40px;
}
  
.navbar #open {
    padding-left: 0px;
}
.navbar #open img {
    display: none;
}
  
.navbar #close img {
    display: inherit;
}
  
.navbar #open .hiddenLinks {
    display: inherit;
    margin-left: 30px;
}
  
.navbar #close .hiddenLinks {
    display: none;
}
  
.navbar #open a {
    width: 70px;
    margin: 5px;
}
@media only screen and (max-width: 700px) {
    .navbar .rightSide a {
      width: 70px;
    }
    .navbar .leftSide {
      padding-left: 50px;
    }
}
/* the width of the screen when the button appears */
@media only screen and (max-width: 700px) {
    .navbar .rightSide a {
      display: none;
    }
  
    .navbar .rightSide {
      justify-content: flex-end;
      padding-right: 50px;
    }
  
    .navbar .rightSide button {
      display: inherit;
    }
}
 
@media only screen and (min-width: 700px) {
    .navbar .rightSide button {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }
}
