.home {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: rgb(2,0,36);
    font-family: "Gill Sans", 
    "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}  

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.headerContainer {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center center;
    background-size: cover;  
    filter: brightness(80%);
}

.headerContainer h1 {
    justify-content: center;
    align-items: center;
    font-size: 80px;
    height: 60px;
    font-weight: 650;
    color: white;
    animation: fade-in 1s
}

.gradient-text {
  background: linear-gradient(90deg, rgb(5, 46, 253) 0%, 
  rgba(252,70,107,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

.headerContainer h2 {
    margin-top: 10px;
    font-size: 45px;
    font-weight: 650;
    color: white;
    animation: fade-in 2s
}

.headerContainer button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    background: rgb(63,94,251);
    background: radial-gradient(circle, rgba(63,94,251,1) 0%, 
    rgba(252,70,107,1) 100%);
    height: 50px;
    width: 350px;
    color: white;
    text-decoration: none;
    font-size: 20px;
    animation: fade-in 3s
}

.headerContainer button:hover {
    background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%,
    rgba(148,187,233,1) 100%);
    cursor: pointer;
    transition: 0.2s ease-in;
}

@media only screen and (max-width: 800px) {
    .home {
      justify-content: center;
      align-items: center;
    }
  
    .headerContainer {
      margin-left: 0px;
      border-radius: 0;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #121619;
      color: white;
    }
  
    .headerContainer h1 {
      font-size: 40px;
      height: auto;
      color: black;
    }
    .headerContainer h2 {
      font-size: 30px;
      height: auto;
      color: black;
    }
  
    .headerContainer button {
      color: white;
      margin-bottom: 30px;
      height: 50px;
      width: 150px;
      font-size: 15px;

    }
  
    .headerContainer button:hover {
      background-color: rgb(225, 225, 225);
      color: white;
    }
}

@media only screen and (max-width: 480px) {
  .headerContainer h1 {
      font-size: 28px;  
      height: auto;
      color: black;
      margin: 8px 0;
  }

  .headerContainer h2 {
      font-size: 20px;  
      height: auto;
      color: black;
      margin: 8px 0;
  }

  .headerContainer button {
      height: 45px;
      width: 180px;  
      font-size: 14px;
  }
}