
.container {
    overflow-y: scroll; /* Allow scrolling within the container */
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 100vh; 
    flex-direction: column;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgb(2, 49, 35) 0%,
    rgb(1, 57, 92) 100%);
    overflow: auto;
    flex: 1;
    background-size: contain;

}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(30px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}


.contact {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.contact .leftSide {
    height: 100%;
    flex: 50%;
    align-items: center;
    
}

.iconContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconContainer .MuiSvgIcon-root {
  margin-top: 150px;
  font-size: 25rem;
}

.contact .rightSide {
    height: 100%;
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
}

.contact .rightSide h1 {
    font-family: Arial, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 50px;
    margin-left: 20px;
    color: white;
    margin-top: 80px;
    animation: fade-in 2s;
  }

  .contact .rightSide h2 {
    font-family: Arial, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 20px;
    margin-left: 20px;
    color: white;
    margin-top: 5px;
    animation: fade-in 2s;
  }

  form {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 20px;
    color: white;
    margin-top: -20px;
    animation: fade-up 1s;
  }
  
  form input {
    height: 40px;
    width: 80%;
    border: none;
    border-bottom: 1px solid #121619;
    color: black;
  }

  form textarea {
    margin-top: 15px;
    height: 70px;
    width: 80%;
    border: none;
    border-bottom: 1px solid #121619;
    color: black;
  }

  input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
}
form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;
  margin-bottom: 2px;
  color: White;
}

.defaultButton {
  margin-top: 40px;
  margin-bottom: 30px;
  width: 160px;
  height: 50px;
  border: none;
  background-color: #da8302;
  border-radius: 70px;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
}

.defaultButton:hover {
    background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%,
    rgba(148,187,233,1) 100%);
    cursor: pointer;
    transition: 0.2s ease-in;
}


.sentButton {
    margin-top: 40px;
    margin-bottom: 30px;
    width: 160px;
    height: 50px;
    border: none;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%,
    rgba(102,173,203,0.9936102236421726) 0%, rgba(180,5,110,1) 89%);
    border-radius: 70px;
    color: whitesmoke;
    font-size: 15px;
    cursor: pointer;
}

.footer {
  width: 100%;
  margin-top: auto; /* Ensures the footer stays at the bottom */
}


@media (max-width: 1200px) {
  .iconContainer .MuiSvgIcon-root {
      margin-top: 150px;
      font-size: 25rem;
  }
}

@media (max-width: 992px) {
  .iconContainer .MuiSvgIcon-root {
      margin-top: auto;
      font-size: 15rem;
  }
}

@media (max-width: 768px) {
  .iconContainer .MuiSvgIcon-root {
      margin-top: auto;
      font-size: 10rem;
      
  }
}

@media (max-width: 576px) {
  .iconContainer .MuiSvgIcon-root {
      margin-top: 300px;
      font-size: 8rem;
  }
}

@media (max-width: 480px) {
  .iconContainer .MuiSvgIcon-root {
      margin-top: auto;
      font-size: 6rem;
  }
}