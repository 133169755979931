.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif

}

body {
  padding:0; 
  margin:0;
}
