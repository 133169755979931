.about {
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 100vh; 
    flex-direction: column;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgb(2, 49, 35) 0%,
    rgb(1, 57, 92) 100%);
    overflow: auto;
    overflow-y: auto;
    flex: 1;
    background-size: contain;
    margin: auto;
}

@keyframes fade-down {
    0% {
        opacity: 0;
        transform: translateY(-30px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}
@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(30px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.container {
    height: 100%;
    overflow-y: scroll; /* Allow scrolling within the container */
}

.photoSelf {
    width: 100%;
    height: 50vh;
    padding-top: 5%;
    padding-bottom: -15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 600px;
    animation: fade-down 2s
}
.aboutTitle {
    align-items: center;
    background: linear-gradient(90deg, #3ff8fb 0%, #b8e0df 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 10px;
    animation: fade-in 2s

}


.aboutDescription{
    color: white;
    text-align: center;
    flex: 1;
    justify-content: flex-start;
    margin-top: -20px;
    overflow-y: auto;
    height: 70vh;
    flex-direction: column;
    animation: fade-in 2s
}

.aboutList {
    margin-bottom: 40px;
    margin-top: 40px;
    animation: fade-up 3s
}

.aboutList h2{
    text-align: left;
    flex: 1;
    margin-left: 32%;
}

.aboutList p{
    text-align: left;
    flex: 1;
    margin-left: 30%;
    margin-right: 30%;
    font-size: larger;
}

.paragraph h4{
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
    font-size: larger;
    font-weight: 750;
}

.paragraph h2{
    background: linear-gradient(90deg, rgba(239,241,75,1) 9%, rgba(228,92,235,1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 50px;
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;

}
/*
.paragraph h3{
    background: linear-gradient(0deg, rgb(241, 75, 202) 9%, rgb(92, 235, 223) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 40px;
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;

} */

.contactButton button {
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 50px;
    cursor: pointer;
    justify-content: center;
    background: rgb(63,94,251);
    background: radial-gradient(circle, rgba(63,94,251,1) 0%, 
    rgba(252,70,107,1) 100%);
    height: 50px;
    width: 300px;
    color: white;
    text-decoration: none;
    font-size: 30px;
}

.contactButton button:hover {
    background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%,
    rgba(148,187,233,1) 100%);
    cursor: pointer;
    transition: 0.2s ease-in;
}