.footer {
    width: 100%;
    height: 100px;
    background-color: #121619;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    flex:1 ;
}
  
.socialmedia svg {
    color: white;
    margin: 20px;
    font-size: 60px;
    cursor: pointer;
}
  
.socialmedia button {
    border: none;
    background: transparent;
}


